import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

const Photography = () => {
  return ( 
      <>
        <Navigation />
        <div className="pageContainer">        
        </div>
        <Footer />
      </>
    )
  };
  
  export default Photography;